import { GatsbyImage } from "gatsby-plugin-image";
import { CalculateFromHeight, CalculateFromWidth } from "styled/base/SizeFunctions";
import {
  ContactTitleIdeaFont,
  ContactTitleNameFont,
  ContactTitlePositionFont
} from "styled/common/font-styles/ContactFonts";
import { NonSelectable } from "styled/common/Utils";
import styled from "styled-components";

export const ContactTitle = styled.section`
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(15, 1fr);
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(16, 1fr);
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(21, 1fr);
  }
  ${CalculateFromHeight("height", { mobile: 260, tablet: 135.5, desktop: 134.4 })}
  display: grid;
  width: 100%;
`;

export const ContactTitleIdea = styled.p`
  ${ContactTitleIdeaFont}
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-column: 1 / span 5;
    grid-row: 7 / span 3;
    margin-top: auto;
    margin-bottom: auto;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-column: 1 / span 6;
    grid-row: 6 / span 2;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-column: 1 / span 6;
    grid-row: 7 / span 3;
  }
  ${CalculateFromWidth("margin-right", { mobile: 10, tablet: 4, desktop: 4 })}
  ${CalculateFromWidth("margin-left", { mobile: 6.4, tablet: 5.2, desktop: 3.3 })}
  ${CalculateFromHeight("margin-top", { mobile: -5 })}
`;

export const ContactTitleHorizontal = styled.div`
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-column: 1 / span 6;
    grid-row: 10 / span 1;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-column: 1 / span 8;
    grid-row: 13 / span 1;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-column: 1 / span 7;
    grid-row: 17 / span 4;
  }
  ${CalculateFromHeight("height", { mobile: 0.2, tablet: 0.2, desktop: 0.2 })}
  ${CalculateFromHeight("margin-top", { mobile: -2, tablet: 6, desktop: 3 })}
  ${CalculateFromWidth("margin-left", { mobile: 6.4, tablet: 5.2, desktop: 3.3 })}
  ${CalculateFromWidth("margin-right", { mobile: 6.4, tablet: 5.2 })}
  ${CalculateFromWidth("border-bottom-width", { mobile: 0.1, tablet: 0.1, desktop: 0.1 })}
  border-bottom-style: solid;
  border-bottom-color: #ccc;
  opacity: 0.6;
`;
export const ContactTitleImageWrapper = styled.div`
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-column: 1 / span 2;
    grid-row: 12 / span 2;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-column: 1 / span 2;
    grid-row: 15 / span 2;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-column: 1 / span 2;
    grid-row: 18 / span 2;
  }
  ${CalculateFromWidth("height", { mobile: 19.2, tablet: 13.5, desktop: 6.4 })}
  ${CalculateFromWidth("width", { mobile: 19.2, tablet: 13.5, desktop: 6.4 })}
  ${CalculateFromHeight("margin-top", { mobile: -1.6, tablet: -2 })}
  ${CalculateFromWidth("margin-left", { mobile: 6.4, tablet: 5.2, desktop: 3.3 })}
  ${CalculateFromWidth("margin-right", { desktop: 1 })}
`;
export const ContactTitlePhotoFace = styled(GatsbyImage)`
  ${NonSelectable}
  border-radius: 50%;
  margin: 0;
  transform: scaleX(-1);
`;
export const ContactTitleName = styled.p`
  ${ContactTitleNameFont}
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-column: 1 / span 2;
    grid-row: 13 / span 1;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-column: 3 / span 2;
    grid-row: 15 / span 1;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-column: 3 / span 2;
    grid-row: 18 / span 1;
  }
  ${CalculateFromHeight("margin-top", { mobile: 1, tablet: -0.9, desktop: 2 })}
  ${CalculateFromWidth("margin-left", { mobile: 2, tablet: 1.3, desktop: 3.3 })}
`;
export const ContactTitlePosition = styled.p`
  ${ContactTitlePositionFont}
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-column: 1 / span 2;
    grid-row: 13 / span 1;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-column: 3 / span 2;
    grid-row: 15 / span 1;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-column: 3 / span 2;
    grid-row: 19 / span 1;
  }
  ${CalculateFromHeight("margin-top", { mobile: -0.5, tablet: 5.8, desktop: 7.2 })}
  ${CalculateFromWidth("margin-left", { mobile: 2.2, tablet: 1.3, desktop: 3.3 })}
`;
