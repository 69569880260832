import { Link } from "gatsby";
import { CalculateFromHeight } from "styled/base/SizeFunctions";
import {
  NewsletterCheckboxLabelFont,
  NewsletterDescriptionFont,
  NewsletterInputFont,
  NewsletterPrivacyPolicyFont,
  NewsletterPrivacyPolicyLinkFont
} from "styled/common/font-styles/CommonFonts";
import styled, { css } from "styled-components";

export const NewsletterWrapper = styled.section`
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-template-rows: repeat(12, 1fr);
    grid-template-columns: repeat(12, 1fr);
  }

  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-template-rows: repeat(12, 1fr);
    grid-template-columns: repeat(12, 1fr);
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-template-rows: repeat(18, 1fr);
    grid-template-columns: repeat(12, 1fr);
  }
  ${CalculateFromHeight("height", { mobile: 200, tablet: 100, desktop: 85 })}
  ${CalculateFromHeight("margin-top", { mobile: 5, desktop: 30 })}
  ${CalculateFromHeight("margin-bottom", { mobile: 15, tablet: 15, desktop: 15 })}
  z-index: 1;
  display: grid;
  width: 100%;
  position: relative;
`;

export const NewsletterDescription = styled.p`
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-column: 2 / span 10;
    grid-row: 4 / span 3;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-column: 2 / span 9;
    grid-row: 4 / span 2;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-column: 2 / span 10;
    grid-row: 5 / span 2;
  }
  ${CalculateFromHeight("margin-top", { mobile: 2, tablet: 0, desktop: 2 })}
  ${NewsletterDescriptionFont}
`;

export const NewsletterForm = styled.form`
  display: grid;
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-row: 7 / span 7;
    grid-column: 2 / span 12;
    grid-template-rows: repeat(12, 1fr);
    grid-template-columns: repeat(12, 1fr);
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-row: 7 / span 14;
    grid-column: 1 / span 12;
    grid-template-rows: repeat(12, 1fr);
    grid-template-columns: repeat(12, 1fr);
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-row: 11 / span 10;
    grid-column: 1 / span 12;
    grid-template-rows: repeat(12, 1fr);
    grid-template-columns: repeat(12, 1fr);
  }
`;

const NewsletterInputShared = css`
  border: none;
  border-bottom-style: solid;
  border-bottom-color: ${({ theme: { colors } }) => colors.white};
  border-bottom-width: 1.5px;
  outline: none;
  background-color: transparent;
  color-scheme: dark;
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    border-bottom-style: double;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    border-bottom-style: double;
  }
  ${CalculateFromHeight("margin-top", { desktop: 2 })}
  ${CalculateFromHeight("height", { mobile: 6, tablet: 4, desktop: 6 })}
  ${NewsletterInputFont}
`;

export const NewsletterNameInput = styled.input`
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-column: 1 / span 6;
    grid-row: 2 / span 2;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-column: 2 / span 8;
    grid-row: 1 / span 3;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-column: 2 / span 9;
    grid-row: 1 / span 4;
  }
  ${NewsletterInputShared}
`;

export const NewsletterEmailInput = styled.input`
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-column: 1 / span 6;
    grid-row: 3 / span 2;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-column: 2 / span 8;
    grid-row: 2 / span 3;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-column: 2 / span 9;
    grid-row: 3 / span 4;
  }
  ${NewsletterInputShared}
  ${CalculateFromHeight("margin-top", { mobile: 2, tablet: 3, desktop: 8 })}
`;

export const NewsletterInformation = styled.span`
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-row: 5 / span 1;
    grid-column: 1 / span 6;
    @supports (-moz-appearance: none) {
      padding-top: 2.5rem;
    }
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-row: 4 / span 1;
    grid-column: 2 / span 5;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-row: 4 / span 2;
    grid-column: 2 / span 10;
  }
  ${CalculateFromHeight("margin-top", { mobile: 5, tablet: 1, desktop: 2 })}
  ${CalculateFromHeight("font-size", { mobile: 3.5 })}
`;

export const NewsletterButton = styled.div`
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-row: 4 / span 2;
    grid-column: 9 / span 3;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-row: 7 / span 4;
    grid-column: 7 / span 4;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-row: 7 / span 6;
    grid-column: 6 / span 6;
  }
  ${CalculateFromHeight("margin", { mobile: 1, desktop: 4 })}
  ${CalculateFromHeight("margin-top", { mobile: 5, desktop: 4 })}
`;

export const NewsletterPrivacyPolicy = styled.span`
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-row: 7 / span 1;
    grid-column: 1 / span 6;
    @supports (-moz-appearance: none) {
      padding-top: 2.5rem;
    }
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-row: 5 / span 1;
    grid-column: 2 / span 10;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-row: 5 / span 2;
    grid-column: 2 / span 8;
  }
  ${CalculateFromHeight("margin-top", { mobile: 5 })}
  ${NewsletterPrivacyPolicyFont}
`;

export const NewsletterPrivacyPolicyLink = styled(Link)`
  ${NewsletterPrivacyPolicyLinkFont}
`;

export const NewsletterCheckboxNews = styled.div`
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-row: 6 / span 1;
    grid-column: 2 / span 4;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-row: 6 / span 1;
    grid-column: 2 / span 10;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-row: 8 / span 2;
    grid-column: 2 / span 8;
  }
  ${CalculateFromHeight("margin-top", { desktop: 5 })}
`;

export const NewsletterCheckboxJobs = styled.div`
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-row: 6 / span 1;
    grid-column: 4 / span 4;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-row: 6 / span 1;
    grid-column: 4 / span 4;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-row: 9 / span 2;
    grid-column: 2 / span 8;
  }
  ${CalculateFromHeight("margin-top", { desktop: 5 })}
`;

export const NewsletterCheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const NewsletterCheckboxLabel = styled.div`
  position: relative;
  width: 20px;
  height: 20px;
  border: 1px solid ${({ theme: { colors } }) => colors.white};

  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    width: 25px;
    height: 25px;
  }
`;

export const NewsletterStyledCheckbox = styled.input`
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99;
  opacity: 0;
  cursor: pointer;
`;

export const NewsletterCheckmark = styled.img`
  width: 27px;
  height: 20px;
  position: absolute;
  top: 40%;
  left: 80%;
  transform: translate(-50%, -50%);

  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    width: 32px;
    height: 25px;
  }
`;

export const NewsletterCheckboxText = styled.span`
  ${CalculateFromHeight("margin-left", { mobile: 4, tablet: 2, desktop: 2 })}
  ${NewsletterCheckboxLabelFont}
`;
