import { CalculateFromHeight, CalculateFromWidth } from "styled/base/SizeFunctions";
import styled from "styled-components";

export const ContactMainButtonWrapper = styled.div`
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-column: 9 / span 4;
    grid-row: 1 / span 13;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-column: 3 / span 4;
    grid-row: 8 / span 4;

    display: block;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-column: 2 / span 4;
    grid-row: 10 / span 6;
    display: block;
  }
  ${CalculateFromHeight("margin-bottom", { mobile: 1.1, tablet: -3.7 })}
  ${CalculateFromHeight("margin-top", { mobile: 7, tablet: 1 })}
`;

export const ContactTitleButtonWrapper = styled.div`
  ${CalculateFromWidth("margin-left", { mobile: 1.2, tablet: 3.75 })}
  ${CalculateFromWidth("margin-right", { mobile: 1.2, tablet: 3.75, desktop: 3.4 })}
  ${CalculateFromHeight("margin-top", { desktop: 15.2 })}
`;
