import { Background, SizeFillSpaceMixin } from "styled/common/Utils";
import styled from "styled-components";

export const CloudMovementArea = styled.section`
  ${SizeFillSpaceMixin}
  position: absolute;
  top: 0;
  pointer-events: none;
  ${Background}
`;

export const CloudMovementAreaContent = styled.div`
  ${SizeFillSpaceMixin}
  position: absolute;
  top: 0;
  pointer-events: none;
  ${Background}
`;
