import React from "react";

import Newsletter from "components/common/sections/Newsletter/Newsletter";
import Layout from "components/layout/Layout/Layout";
import ContactCareers from "components/pages/contact/ContactCareers/ContactCareers";
import ContactLocalization from "components/pages/contact/ContactLocalization/ContactLocalization";
import ContactMainSection from "components/pages/contact/ContactMainSection/ContactMainSection";
import MainCloud from "components/rendering/MainCloud/MainCloud";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import type { PageProps } from "pages/index";
import { GetSeoData } from "utils/seo-utils";

const Contact = ({ location }: PageProps) => {
  return (
    <>
      <GatsbySeo {...GetSeoData("Contact")} />
      <Layout location={location}>
        <MainCloud color="VioletBlue" variant="MainCloudWrapper" scrollSpeed={-3.5} />
        <ContactMainSection />
        <ContactLocalization />
        <ContactCareers />
        <Newsletter cloudVariant="NewsletterCloudContact" />
      </Layout>
    </>
  );
};

export default Contact;
