import React, { useState } from "react";

import {
  NewsletterCheckboxLabel,
  NewsletterCheckboxText,
  NewsletterCheckboxWrapper,
  NewsletterCheckmark,
  NewsletterStyledCheckbox
} from "components/common/sections/Newsletter/Newsletter.styled";
import Checkmark from "media/images/icons/other/checkmark.svg";

interface NewsletterCheckboxProps {
  label: string;
  onChange: (value: boolean) => void;
  defaultValue?: boolean;
}

export const NewsletterCheckbox = ({
  label,
  onChange,
  defaultValue = true
}: NewsletterCheckboxProps) => {
  const [isChecked, setIsChecked] = useState(defaultValue);

  return (
    <NewsletterCheckboxWrapper>
      <NewsletterCheckboxLabel>
        <NewsletterStyledCheckbox
          type="checkbox"
          checked={isChecked}
          onChange={event => {
            onChange(event.target.checked);
            setIsChecked(event.target.checked);
          }}
        />
        {isChecked && <NewsletterCheckmark src={Checkmark} alt="checkbox-checkmark" />}
      </NewsletterCheckboxLabel>
      <NewsletterCheckboxText>{label}</NewsletterCheckboxText>
    </NewsletterCheckboxWrapper>
  );
};
