import {
  StandardButtonCircleWrapper,
  StandardButtonText,
  StandardButtonWrapper
} from "components/common/buttons/ButtonStandard/ButtonStandard.styled";
import { GatsbyImage } from "gatsby-plugin-image";
import { CalculateFromHeight, CalculateFromWidth } from "styled/base/SizeFunctions";
import {
  ContactLocalizationDescriptionFont,
  ContactLocalizationFont,
  ContactLocalizationLinkFont
} from "styled/common/font-styles/ContactFonts";
import { DisableSelection, NonSelectable } from "styled/common/Utils";
import styled, { css } from "styled-components";

const ContactPageStandardButton = css`
  ${StandardButtonWrapper} {
    ${CalculateFromWidth("width", { desktop: 16.88 })}
  }
  ${StandardButtonCircleWrapper} {
    ${CalculateFromWidth("width", { mobile: 16.65, tablet: 10.15 })}
    ${CalculateFromHeight("height", { mobile: 18.24, tablet: 8.69 })}
  }
  ${StandardButtonText} {
    ${CalculateFromWidth("width", { mobile: 40, tablet: 20 })}
  }
`;

export const ContactLocalizationWrapper = styled.section`
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: repeat(15, 1fr);
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: repeat(15, 1fr);
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(20, 1fr);
  }
  ${CalculateFromHeight("height", { mobile: 200, tablet: 130, desktop: 65.3 })}
  ${CalculateFromHeight("margin-top", { tablet: 1.5, desktop: 23.75 })}
  display: grid;
  width: 100%;
`;

export const ContactLocalizationTitle = styled.p`
  ${ContactLocalizationFont}
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-column: 1 / span 3;
    grid-row: 3 / span 2;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-column: 1 / span 5;
    grid-row: 9 / span 2;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-column: 1 / span 3;
    grid-row: 11 / span 2;
  }
  ${CalculateFromHeight("margin-top", { mobile: 4, tablet: 2.4, desktop: 2.2 })}
  ${CalculateFromWidth("margin-left", { mobile: 6.4, tablet: 5.2, desktop: 3.3 })}
`;

export const ContactLocalizationDescription = styled.p`
  ${ContactLocalizationDescriptionFont}
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-column: 1 / span 3;
    grid-row: 6 / span 2;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-column: 1 / span 4;
    grid-row: 11 / span 1;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-column: 1 / span 5;
    grid-row: 13 / span 2;
  }
  ${CalculateFromHeight("margin-top", { mobile: 5, tablet: 0.8, desktop: 2 })}
  ${CalculateFromWidth("margin-left", { mobile: 6.4, tablet: 5.2, desktop: 3.3 })}
  ${CalculateFromWidth("margin-right", { desktop: 6 })}
`;

export const ContactLocalizationDetail = styled.p`
  ${ContactLocalizationDescriptionFont}
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-column: 1 / span 2;
    grid-row: 8 / span 2;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-column: 1 / span 4;
    grid-row: 12 / span 1;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-column: 1 / span 3;
    grid-row: 15 / span 2;
  }
  ${CalculateFromHeight("margin-top", { tablet: 1, desktop: 1 })}
  ${CalculateFromWidth("margin-left", { mobile: 6.4, tablet: 5.2, desktop: 3.3 })}
  ${CalculateFromWidth("margin-right", { mobile: 6, tablet: 8, desktop: 4.6 })}
`;

export const ContactLocalizationDetailCountry = styled.p`
  ${ContactLocalizationDescriptionFont}
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-column: 1 / span 1;
    grid-row: 10 / span 1;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-column: 1 / span 4;
    grid-row: 13 / span 1;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-column: 1 / span 4;
    grid-row: 17 / span 1;
  }
  ${CalculateFromHeight("margin-top", { mobile: 0.5, tablet: 2, desktop: 0.5 })}
  ${CalculateFromWidth("margin-left", { mobile: 6.4, tablet: 5.2, desktop: 3.3 })}
`;

export const ContactLocalizationImageWrapper = styled.div`
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-column: 4 / span 7;
    grid-row: 1 / span 15;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-column: 1 / span 15;
    grid-row: 1 / span 7;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-column: 1 / span 15;
    grid-row: 3 / span 7;
  }
  ${CalculateFromWidth("margin-left", { mobile: 6.4, tablet: 5.2, desktop: 2.4 })}
  ${CalculateFromWidth("margin-right", { mobile: 6.4, tablet: 5.2, desktop: 3.45 })}
  ${CalculateFromHeight("margin-bottom", { mobile: -2.3, tablet: -2.3 })}
`;

export const ContactLocalizationImage = styled(GatsbyImage)`
  ${DisableSelection}
  ${NonSelectable}
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    margin: 0;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    padding-bottom: 13%;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    padding-bottom: 13%;
  }
`;

export const ContactLocalizationButtonWrapper = styled.div`
  ${ContactLocalizationLinkFont}
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-column: 1 / span 2;
    grid-row: 12 / span 2;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-column: 1 / span 3;
    grid-row: 14 / span 2;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-column: 1 / span 4;
    grid-row: 18 / span 3;
  }
  ${CalculateFromHeight("margin-top", { mobile: 6, tablet: 1.8 })}
  ${CalculateFromWidth("margin-left", { mobile: 6.4, tablet: 5.2, desktop: 3.3 })}
  ${ContactPageStandardButton}
`;
