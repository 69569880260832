import * as React from "react";
import { isMobile } from "react-device-detect";

import TrackedButton from "components/common/analytics/TrackedButton";
import CtaButton from "components/common/buttons/ButtonCta/ButtonCta";
import LocomotiveElement from "components/locomotive/LocomotiveElement/LocomotiveElement";
import {
  ContactMainButtonWrapper,
  ContactTitleButtonWrapper
} from "components/pages/contact/ContactMainButtonSection/ContactMainButtonSection.styled";

export const ContactMainButtonSection = () => {
  return (
    <ContactMainButtonWrapper id="contact-main-button-wrapper">
      <ContactTitleButtonWrapper>
        <LocomotiveElement
          scrollSticky="center"
          scrollDelay={0.55}
          scrollTarget="#contact-main-button-wrapper"
          shouldAnimate={!isMobile}
        >
          <TrackedButton category="form" action="contact-form-open">
            <CtaButton variant="contact-page-cta-button-font" />
          </TrackedButton>
        </LocomotiveElement>
      </ContactTitleButtonWrapper>
    </ContactMainButtonWrapper>
  );
};

export default ContactMainButtonSection;
