import * as React from "react";

import ContactInfoSection from "components/common/contact/contact-info/ContactInfoSection/ContactInfoSection";
import LetsTalk from "components/common/sections/LetsTalk/LetsTalk";
import ContactMainButtonSection from "components/pages/contact/ContactMainButtonSection/ContactMainButtonSection";
import {
  ContactTitle,
  ContactTitleHorizontal,
  ContactTitleIdea,
  ContactTitleImageWrapper,
  ContactTitleName,
  ContactTitlePhotoFace,
  ContactTitlePosition
} from "components/pages/contact/ContactMainSection/ContactMainSection.styled";
import { useImageDatabaseContext } from "contexts/ImageContext";
import Translate from "utils/translation/translation";

interface ContactMainSectionProps {
  imageName?: string;
}

export const ContactMainSection = ({ imageName = "PiotrPenarSmall" }: ContactMainSectionProps) => {
  const [imageDatabase] = useImageDatabaseContext();
  const image = imageDatabase[imageName].childImageSharp.gatsbyImageData;

  return (
    <ContactTitle>
      <LetsTalk variant="contact" instantPlayback isTitle />
      <ContactTitleIdea>{Translate("Contact_Encouragament")}</ContactTitleIdea>
      <ContactTitleHorizontal></ContactTitleHorizontal>
      <ContactTitleImageWrapper>
        <ContactTitlePhotoFace image={image} draggable={false} alt="Photo face" />
      </ContactTitleImageWrapper>
      <ContactTitleName>{Translate("Contact_Name_PiotrPenar")}</ContactTitleName>
      <ContactTitlePosition>{Translate("Contact_Founder")}</ContactTitlePosition>
      <ContactInfoSection isFooter={false} />
      <ContactMainButtonSection />
    </ContactTitle>
  );
};

export default ContactMainSection;
