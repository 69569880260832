import React, { useRef } from "react";

import TrackedButton from "components/common/analytics/TrackedButton";
import StandardButton from "components/common/buttons/ButtonStandard/ButtonStandard";
import {
  ContactLocalizationButtonWrapper,
  ContactLocalizationDescription,
  ContactLocalizationDetail,
  ContactLocalizationDetailCountry,
  ContactLocalizationImage,
  ContactLocalizationImageWrapper,
  ContactLocalizationTitle,
  ContactLocalizationWrapper
} from "components/pages/contact/ContactLocalization/ContactLocalization.styled";
import { useImageDatabaseContext } from "contexts/ImageContext";
import { registerScrollAnimation, useScrollContext } from "contexts/ScrollContext";
import { useFirstRenderEffect } from "hooks/useFirstRenderEffect";
import Translate from "utils/translation/translation";

interface LocalizationProps {
  imageName?: string;
}

export const ContactLocalization = ({ imageName = "OfficePhoto" }: LocalizationProps) => {
  const [imageDatabase] = useImageDatabaseContext();
  const localizationTitle = useRef<HTMLParagraphElement>(null);
  const localizationDescription = useRef<HTMLParagraphElement>(null);
  const localizationDetail = useRef<HTMLParagraphElement>(null);
  const localizationDetailCountry = useRef<HTMLParagraphElement>(null);
  const localizationButton = useRef<HTMLDivElement>(null);
  const localizationWrapper = useRef<HTMLElement>(null);
  const localizationPhoto = useRef<HTMLDivElement>(null);
  const [, dispatch] = useScrollContext();
  const image = imageDatabase[imageName].childImageSharp.gatsbyImageData;

  useFirstRenderEffect(() => {
    dispatch(
      registerScrollAnimation({
        target: localizationTitle.current as HTMLElement,
        animation: { opacity: 0, duration: 1.5, delay: 0.25 },
        triggerOffsetMultiplier: -0.25,
        proxyElement: localizationWrapper.current
      })
    );
    dispatch(
      registerScrollAnimation({
        target: localizationDescription.current as HTMLElement,
        animation: { opacity: 0, duration: 1.5, delay: 0.5 },
        triggerOffsetMultiplier: -0.25,
        proxyElement: localizationWrapper.current
      })
    );
    dispatch(
      registerScrollAnimation({
        target: localizationDetail.current as HTMLElement,
        animation: { opacity: 0, duration: 1.5, delay: 0.75 },
        triggerOffsetMultiplier: -0.25,
        proxyElement: localizationWrapper.current
      })
    );
    dispatch(
      registerScrollAnimation({
        target: localizationDetailCountry.current as HTMLElement,
        animation: { opacity: 0, duration: 1.5, delay: 1 },
        triggerOffsetMultiplier: -0.25,
        proxyElement: localizationWrapper.current
      })
    );
    dispatch(
      registerScrollAnimation({
        target: localizationButton.current as HTMLElement,
        animation: { opacity: 0, duration: 1.5, delay: 1.25 },
        triggerOffsetMultiplier: -0.25,
        proxyElement: localizationWrapper.current
      })
    );
    dispatch(
      registerScrollAnimation({
        target: localizationPhoto.current as HTMLElement,
        animation: { opacity: 0, duration: 1.5 },
        triggerOffsetMultiplier: -0.25,
        proxyElement: localizationWrapper.current
      })
    );
  });

  return (
    <ContactLocalizationWrapper ref={localizationWrapper}>
      <ContactLocalizationTitle ref={localizationTitle}>
        {Translate("Contact_Gliwice")}
      </ContactLocalizationTitle>
      <ContactLocalizationDescription ref={localizationDescription}>
        {Translate("Contact_Technopark")}
      </ContactLocalizationDescription>
      <ContactLocalizationDetail ref={localizationDetail}>
        {Translate("Contact_Address")}
      </ContactLocalizationDetail>
      <ContactLocalizationDetailCountry ref={localizationDetailCountry}>
        {Translate("Contact_Country")}
      </ContactLocalizationDetailCountry>
      <ContactLocalizationButtonWrapper>
        <TrackedButton category="contact" action="contact-see-map">
          <StandardButton
            url={"https://g.page/DreamStormStudios?share"}
            text="Contact_Link_SeeOnTheMap"
            external
            buttonRef={localizationButton}
          />
        </TrackedButton>
      </ContactLocalizationButtonWrapper>
      <ContactLocalizationImageWrapper ref={localizationPhoto}>
        <ContactLocalizationImage image={image} draggable={false} alt="Localization" />
      </ContactLocalizationImageWrapper>
    </ContactLocalizationWrapper>
  );
};

export default ContactLocalization;
