import React, { useEffect, useRef } from "react";

import LocomotiveElement from "components/locomotive/LocomotiveElement/LocomotiveElement";
import type { LocomotiveElementVariant } from "components/locomotive/LocomotiveElement/LocomotiveElementVariants";
import {
  CloudMovementArea,
  CloudMovementAreaContent
} from "components/rendering/MainCloud/MainCloud.styled";
import RendererWrapper from "components/rendering/RendererWrapper/RendererWrapper";
import { addCloud, useRenderContext } from "contexts/RenderContext";
import { useFirstRenderState } from "hooks/useFirstRenderState";

interface MainCloudProps {
  variant: LocomotiveElementVariant;
  color: string;
  scrollSpeed: number;
}

export const MainCloud = ({ variant, color, scrollSpeed }: MainCloudProps) => {
  const mainCloudWrapper = useRef<HTMLDivElement>(null);
  const [, dispatchRender] = useRenderContext();
  const firstRender = useFirstRenderState();
  const movementAreaWrapper = useRef<HTMLDivElement>(null);
  const cloud = {
    name: color,
    wrapper: mainCloudWrapper.current,
    group: "MainCloud"
  };

  useEffect(() => {
    if (firstRender) {
      return;
    }

    dispatchRender(addCloud(cloud));
  }, [color, firstRender]);

  return (
    <CloudMovementArea ref={movementAreaWrapper}>
      <CloudMovementAreaContent id="cloud-movement-area">
        <LocomotiveElement
          variant={variant}
          scrollSpeed={scrollSpeed ? scrollSpeed : 1}
          scrollTarget="#cloud-movement-area"
          scrollPosition="top"
          scrollDelay={0.95}
        >
          <RendererWrapper
            elementRef={mainCloudWrapper}
            variant="fill-background-space"
            type="cloud"
            additionalData={color}
          />
        </LocomotiveElement>
      </CloudMovementAreaContent>
    </CloudMovementArea>
  );
};

export default MainCloud;
