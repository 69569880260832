import React, { FormEvent, useEffect, useMemo, useRef, useState } from "react";

import TrackedButton from "components/common/analytics/TrackedButton";
import TrackedElement from "components/common/analytics/TrackedElement";
import TrackedInput from "components/common/analytics/TrackedInput";
import CtaButton from "components/common/buttons/ButtonCta/ButtonCta";
import HeaderGroup, {
  type HeaderGroupProps
} from "components/common/headers/HeaderGroup/HeaderGroup";
import {
  NewsletterButton,
  NewsletterCheckboxJobs,
  NewsletterCheckboxNews,
  NewsletterDescription,
  NewsletterEmailInput,
  NewsletterForm,
  NewsletterInformation,
  NewsletterNameInput,
  NewsletterPrivacyPolicy,
  NewsletterPrivacyPolicyLink,
  NewsletterWrapper
} from "components/common/sections/Newsletter/Newsletter.styled";
import { NewsletterCheckbox } from "components/common/sections/Newsletter/NewsletterCheckbox";
import LocomotiveElement from "components/locomotive/LocomotiveElement/LocomotiveElement";
import type { LocomotiveElementVariant } from "components/locomotive/LocomotiveElement/LocomotiveElementVariants";
import RendererWrapper from "components/rendering/RendererWrapper/RendererWrapper";
import { addCloud, useRenderContext } from "contexts/RenderContext";
import { useFirstRenderEffect } from "hooks/useFirstRenderEffect";
import { CheckboxesState, sendNewsletter } from "network/send-newsletter";
import { emailRegex, nameRegex } from "utils/regularExpressions";
import Translate from "utils/translation/translation";

interface NewsletterProps {
  cloudVariant: LocomotiveElementVariant;
}

const INVALID_EMAIL = "Newsletter_Invalid_Email";
const INVALID_NAME = "Newsletter_Invalid_Name";
const INVALID_CHECKBOXES = "Newsletter_Invalid_Checkboxes";
const SUCCESS_RESPONSE = "Newsletter_Success";
const FAILURE_RESPONSE = "Newsletter_Failure";

export const Newsletter = ({ cloudVariant }: NewsletterProps) => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [hasSentForm, setHasSentForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [, dispatchRender] = useRenderContext();
  const [newsletterMessage, setNewsletterMessage] = useState({ content: "", color: "" });
  const emailInput = useRef<HTMLInputElement>(null);
  const nameInput = useRef<HTMLInputElement>(null);
  const rightCloudWrapper = useRef<HTMLDivElement>(null);
  const [checkboxesState, setCheckboxesState] = useState<CheckboxesState>({
    news: true,
    jobs: true
  });
  const cloud = {
    name: "PinkViolet",
    wrapper: rightCloudWrapper.current,
    group: "Newsletter-Cloud"
  };

  const isEmailValid = useMemo(() => emailRegex.test(email), [email]);
  const isNameValid = useMemo(() => nameRegex.test(name), [name]);

  const onSubmit = async (event: FormEvent) => {
    event.preventDefault();
    setNewsletterMessage({ content: "", color: "" });

    if (!isNameValid) {
      setNewsletterMessage({ content: INVALID_NAME, color: "red" });

      return;
    }

    if (!isEmailValid) {
      setNewsletterMessage({ content: INVALID_EMAIL, color: "red" });

      return;
    }

    if (!checkboxesState.jobs && !checkboxesState.news) {
      setNewsletterMessage({ content: INVALID_CHECKBOXES, color: "red" });

      return;
    }

    await handleSendNewsletter();
  };

  const handleSendNewsletter = async () => {
    setIsLoading(true);

    try {
      const response = await sendNewsletter({ email, name, checkboxes: checkboxesState });

      setHasSentForm(true);

      const isStatusCodeValid = response.code === "OK";

      if (isStatusCodeValid) {
        setEmail("");
        setName("");

        if (emailInput.current && nameInput.current) {
          emailInput.current.value = "";
          nameInput.current.value = "";
        }
      }

      const content = isStatusCodeValid ? SUCCESS_RESPONSE : FAILURE_RESPONSE;
      const color = isStatusCodeValid ? "white" : "red";

      setNewsletterMessage({ content, color });
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setNewsletterMessage({ content: FAILURE_RESPONSE, color: "red" });
      setIsLoading(false);
    }
  };

  const handleNewsCheckboxChange = (value: boolean) => {
    setCheckboxesState({ ...checkboxesState, news: value });
  };

  const handleJobsCheckboxChange = (value: boolean) => {
    setCheckboxesState({ ...checkboxesState, jobs: value });
  };

  useFirstRenderEffect(() => {
    dispatchRender(addCloud(cloud));
  });

  useEffect(() => {
    const checkFormData = (email === "" || isEmailValid) && (name === "" || isNameValid);

    if (hasSentForm) {
      return;
    }

    if (checkFormData) {
      setNewsletterMessage({ content: "", color: "" });

      return;
    }

    if (!isNameValid) {
      setNewsletterMessage({ content: INVALID_NAME, color: "red" });

      return;
    }

    if (!isEmailValid) {
      setNewsletterMessage({ content: INVALID_EMAIL, color: "red" });
    }
  }, [name, email]);

  return (
    <NewsletterWrapper>
      <LocomotiveElement variant={cloudVariant} scrollSpeed={-0.25}>
        <RendererWrapper
          elementRef={rightCloudWrapper}
          variant="fill-background-space"
          type="cloud"
          additionalData={cloud.name}
        />
      </LocomotiveElement>
      <HeaderGroup {...headerData} />
      <NewsletterDescription>{Translate("Newsletter_Description")}</NewsletterDescription>
      <NewsletterCheckboxNews>
        <NewsletterCheckbox
          label={Translate("Newsletter_Checkbox_Label_1")}
          onChange={handleNewsCheckboxChange}
        />
      </NewsletterCheckboxNews>
      <NewsletterCheckboxJobs>
        <NewsletterCheckbox
          label={Translate("Newsletter_Checkbox_Label_2")}
          onChange={handleJobsCheckboxChange}
        />
      </NewsletterCheckboxJobs>
      <NewsletterForm onSubmit={onSubmit} name="Blog newsletter form">
        <TrackedInput category="newsletter" action="newsletter-name">
          <NewsletterNameInput
            name="name"
            placeholder={Translate("Newsletter_Input_Name")}
            disabled={isLoading}
            onChange={e => {
              setHasSentForm(false);
              setName(e.target.value);
            }}
            ref={nameInput}
          />
        </TrackedInput>
        <TrackedInput category="newsletter" action="newsletter-email">
          <NewsletterEmailInput
            name="email"
            placeholder={Translate("Newsletter_Input_Email")}
            disabled={isLoading}
            onChange={e => {
              setHasSentForm(false);
              setEmail(e.target.value);
            }}
            ref={emailInput}
          />
        </TrackedInput>
        <NewsletterInformation style={{ color: newsletterMessage.color }}>
          {Translate(newsletterMessage.content)}
        </NewsletterInformation>
        <NewsletterPrivacyPolicy>
          {Translate("Newsletter_PrivacyPolicy") + " "}
          <TrackedElement category="newsletter" action="newsletter-privacy-policy">
            <NewsletterPrivacyPolicyLink to="/privacy-policy/">
              {Translate("Newsletter_PrivacyPolicy_Link")}
            </NewsletterPrivacyPolicyLink>
          </TrackedElement>
        </NewsletterPrivacyPolicy>
        <NewsletterButton>
          <TrackedButton category="newsletter" action="newsletter-submit">
            <CtaButton text="Newsletter_Button" type="submit" color="white" hiderArrow />
          </TrackedButton>
        </NewsletterButton>
      </NewsletterForm>
    </NewsletterWrapper>
  );
};

export default Newsletter;

const headerData: HeaderGroupProps = {
  instantPlayback: false,
  animationPlaybackOffset: 1,
  headers: [
    {
      text: "Newsletter_Header_Newsletter",
      variant: "NewsletterHeaderNewsletter",
      id: "newsletter-header-newsletter",
      horizontalScrollSpeed: -1.25
    }
  ]
};
