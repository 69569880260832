import { CalculateFromHeight, CalculateFromWidth } from "styled/base/SizeFunctions";
import { CommonLightBigFont, CommonLink } from "styled/common/font-styles/CommonFonts";
import { ContactCareersTitleFont, MailAddressFont } from "styled/common/font-styles/ContactFonts";
import styled from "styled-components";

export const ContactCareersWrapper = styled.section`
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(10, 1fr);
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(10, 1fr);
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(14, 1fr);
  }
  ${CalculateFromHeight("height", { mobile: 101.2, tablet: 58, desktop: 85 })}
  ${CalculateFromHeight("margin-top", { tablet: 5.5 })}
  display: grid;
  width: 100%;
`;

export const ContactCareersTitle = styled.div`
  ${ContactCareersTitleFont}
  grid-column: 1 / span 10;
  grid-row: 2 / span 2;
  ${CalculateFromWidth("margin-left", { mobile: 6.4, tablet: 5.2, desktop: 3.3 })}
  ${CalculateFromHeight("margin-top", { desktop: 9.4 })}
`;

export const ContactCareersDescription = styled.p`
  ${CommonLightBigFont}
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-column: 3 / span 7;
    grid-row: 6 / span 3;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-column: 1 / span 8;
    grid-row: 5 / span 4;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-column: 1 / span 6;
    grid-row: 6 / span 7;
  }
  ${CalculateFromWidth("margin-left", { mobile: 6.4, tablet: 5.2, desktop: 8 })}
  ${CalculateFromWidth("margin-right", { mobile: 6.4, tablet: 5.2, desktop: 3.45 })}
  ${CalculateFromHeight("margin-top", { tablet: -1.3 })}
`;

export const ContactCareersJob = styled.a`
  ${CommonLink}
  ${MailAddressFont}
  ${CalculateFromWidth("border-bottom-width", { mobile: 0.2, tablet: 0.2, desktop: 0.15 })}
  border-bottom-style: solid;
  border-bottom-color: ${({ theme: { colors } }) => colors.white};
`;
