import React, { useRef } from "react";

import TrackedElement from "components/common/analytics/TrackedElement";
import HeaderGroup, {
  type HeaderGroupProps
} from "components/common/headers/HeaderGroup/HeaderGroup";
import {
  ContactCareersDescription,
  ContactCareersJob,
  ContactCareersTitle,
  ContactCareersWrapper
} from "components/pages/contact/ContactCareers/ContactCareers.styled";
import { registerScrollAnimation, useScrollContext } from "contexts/ScrollContext";
import { Circ } from "gsap";
import { useFirstRenderEffect } from "hooks/useFirstRenderEffect";
import Translate from "utils/translation/translation";

export const ContactCareers = () => {
  const careersDescription = useRef<HTMLParagraphElement>(null);
  const [, dispatch] = useScrollContext();

  const headerData: HeaderGroupProps = {
    instantPlayback: false,
    animationPlaybackOffset: -2,
    headers: [
      {
        text: "Contact_Header_Careers",
        id: "contact-careers",
        animation: { x: "-20%", opacity: 0, duration: 0.75, delay: 0, ease: Circ.easeOut },
        horizontalScrollSpeed: 0.5
      }
    ]
  };

  useFirstRenderEffect(() => {
    dispatch(
      registerScrollAnimation({
        target: careersDescription.current as HTMLElement,
        animation: { opacity: 0, duration: 1.5 },
        triggerOffsetMultiplier: -0.5,
        proxyElement: careersDescription.current
      })
    );
  });

  return (
    <ContactCareersWrapper>
      <ContactCareersTitle>
        <HeaderGroup {...headerData} />
      </ContactCareersTitle>
      <ContactCareersDescription ref={careersDescription}>
        {Translate("Contact_Careers_Info")}
        <TrackedElement category="contact" action="contact-jobs">
          <ContactCareersJob href={"mailto:" + Translate("Contact_Data_Email_Recruit")}>
            {" " + Translate("Contact_Data_Email_Recruit")}
          </ContactCareersJob>
        </TrackedElement>
      </ContactCareersDescription>
    </ContactCareersWrapper>
  );
};

export default ContactCareers;
